import React from 'react'
import Layout from '../layouts'

import styled from '@emotion/styled'

if (
  typeof window === 'object' &&
  window.location.pathname === '/formular-versendet/'
) {
  const alreadyRedirected = new URLSearchParams(window.location.search).get(
    'redirected'
  )

  if (!alreadyRedirected) {
    window.location = '/formular-versendet/?redirected=true'
  }
}

const Headline = styled.h1``

export default () => (
  <Layout>
    <div>
      <div className="post single page type-page status-publish hentry">
        <div className="post-header">
          <Headline className="post-title">
            Deine Nachricht wurde erfolgreich versendet.
          </Headline>
        </div>
        <article className="post-content">
          <p>
            Ich werde mich so schnell wie möglich mit Dir in Verbindung setzen.
          </p>
        </article>
      </div>
    </div>
  </Layout>
)
